.ProfilePage {
    text-align: center;
    padding: 20px;
    background: #f0f0f0;
}

.profiles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: auto;
    max-width: 960px;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    background: linear-gradient(to right, #ffffff, #f8faff); /* Light gradient for a fresh look */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.12);
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions for interaction */
}

.profile:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(0,0,0,0.15); /* Enhanced shadow on hover for depth */
}

.profile-header {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
}

.profile-header img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 30px;
}

.profile-header div {
    text-align: left;
}

.profile h3 {
    color: #0056b3;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.profile h4 {
    color: #666;
    font-size: 1.2rem;
    margin-top: 5px;
    font-weight: 500; /* Slightly bolder for better readability */
}

.profile-bio {
    text-align: left;
    color: #333;
    font-size: 1rem;
    line-height: 1.8; /* Enhanced for better reading experience */
    width: 100%;
    white-space: pre-wrap; /* Maintains whitespace formatting */
}

@media (max-width: 768px) {
    .profile-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .profile-header img {
        margin-right: 0;
        margin-bottom: 15px;
    }

    .profile {
        align-items: center;
    }

    .profile-bio {
        text-align: left;
        padding: 0 10px;
    }
    .ProfilePage h1 {
        margin-top: 60px;
    }
}

.back-link {
    text-decoration: none;
    color: #007bff; /* Bright blue for a friendly and inviting look */
    font-size: 18px;
    margin: 20px;
    display: inline-block; /* Keeps the link's size to its content only */
    background-color: transparent; /* Ensures there's no background color initially */
    padding: 10px 15px; /* Slightly larger padding for a more button-like feel */
    border-radius: 15px; /* More pronounced rounded corners */
    transition: background-color 0.3s, transform 0.3s, color 0.3s; /* Smooth transitions for hover effects */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.back-link:hover {
    background-color: #e0e0e0; /* Subtle light gray background on hover */
    color: #0056cc; /* Slightly darker blue on hover for visual feedback */
    text-decoration: none;
    transform: translateY(-2px); /* Subtle lift effect on hover */
}
