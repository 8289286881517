.mini-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* This will vertically center all items in the header */
    position: fixed;
    width: 100%;
    background-color: #f4f4f4; /* Light grey background for slight contrast */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 1000;
    top: 0; /* Ensures the header is at the top of the viewport */
    left: 0; /* Ensures the header spans the full width */
    right: 0; /* Ensures the header spans the full width */
}

.header-logo {
    height: 40px; /* Adjust size as needed */
    width: auto;
}

.hamburger-icon {
    font-size: 30px; /* Icon size, adjust if needed */
    background: none;
    border: none;
    cursor: pointer;
    color: #333; /* Icon color, adjust as needed */
    padding: 10px; /* Ensures there's space around the icon for easier tapping */
    z-index: 1050; /* Ensures the icon is above other content */
}

.menu-list {
    position: fixed;
    top: 60px; /* Adjust this value based on the height of your mini header */
    right: 0;
    left: 0;  /* This ensures the menu spans from left to right edge */
    background-color: #fff;
    width: 100vw; /* Sets the width to 100% of the viewport width */
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    z-index: 999; /* Ensure this is high enough to stay on top of other content but below the sticky header */
}

.menu-list a {
    padding: 15px 20px; /* Generous padding for a better touch target */
    text-decoration: none;
    color: #333; /* Text color */
    font-size: 16px; /* Larger font size for readability */
    border-bottom: 1px solid #ddd; /* Separator between menu items */
    width: 100%; /* Ensures links use up the full width available */
    text-align: left;
}

.menu-list a:hover {
    background-color: #e0e0e0; /* Slight change on hover for visual feedback */
}

/* Hide the hamburger menu on desktop screens */
@media (min-width: 769px) {
    .mini-header {
        display: none;
    }
}
