/* General Styles */
.App {
  font-family: 'Helvetica Neue', Arial, sans-serif; /* Clean, modern font */
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #fafafa; /* Soft, light background for a clean, airy feel */
}

/* Header Styles */
.App-header {
  background-color: #f8f8f8; /* Light gray for a subtle, refined look */
  color: #333; /* Dark text for contrast */
  padding: 20px 40px;
  text-align: center;
  font-weight: normal;
  font-size: 24px; /* Clear, readable text */
  border-bottom: 1px solid #eaeaea; /* Subtle separator */
}

/* Banner Styles */
.App-banner {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.App-banner img {
  width: 100%;
  height: auto;
  opacity: 0.9;
}

.App-banner h2 {
  position: absolute;
  top: 6%; /* Adjust this percentage to suit the desktop look */
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vw; /* Starts scaling with the viewport width */
  color: white;
  padding: 0.5vw 1vw; /* Padding also scales with viewport width */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.6); /* For better legibility */
  max-width: 90%; /* Ensures text does not stretch too wide on larger screens */
}

/* Main Content Styles */
.App-content {
  flex: 1;
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
}

section {
  margin: 20px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: none; /* Minimalist design avoids heavy shadows */
  width: 45%;
}

/* Footer Styles */
.App-footer {
  background-color: #f8f8f8; /* Matches the header for a cohesive look */
  color: #333;
  text-align: center;
  padding: 10px 20px;
  font-size: 16px;
  border-top: 1px solid #eaeaea; /* Adds a fine line to delineate the footer */
}

a {
  color: #007aff; /* Blue accents for links */
}

a:hover {
  text-decoration: none; /* Smooth interaction without underline */
  color: #ffffff;
  background-color: #ff9500; /* Slightly darker blue on hover for visual feedback */
}

@media (max-width: 800px) {
  .App-header {
    font-size: 13px; /* Smaller font size for mobile devices */
    padding: 15px 20px; /* Reduced padding for smaller screens */
    margin-top: 60px;
  }
  
  .App-content {
    flex-direction: column;
    align-items: center;
  }

  section {
    width: 90%;
  }

  .App-banner h2 {
    font-size: 3vw; /* Larger font size percentage for smaller screens */
    top: 5%; /* You might want to position it differently on mobile */
}
}


.profiles-link {
  margin-top: 20px;
  text-align: center;
}

.link-button, a.contact-us {
  color: #ffffff;  /* White text for high contrast */
  background-color: #0071e3;  /* Vibrant blue background */
  padding: 12px 24px;  /* Comfortable padding for a button-like appearance */
  border-radius: 20px;  /* Highly rounded corners for a modern, soft look */
  text-decoration: none;  /* No underline to keep it clean */
  transition: background-color 0.3s, transform 0.3s;  /* Smooth transitions for interactive feedback */
  display: inline-block;  /* Ensures proper spacing and alignment */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);  /* Subtle shadow to elevate the button visually */
  font-weight: bold;  /* Bold font for readability */
  margin-top: 20px;  /* Extra space around the link for clear separation */
  border: none;  /* No border for a cleaner look */
}

.link-button:hover, a.contact-us:hover {
  background-color: #0056cc;  /* Slightly darker blue on hover for depth */
  text-decoration: none;  /* Consistency in no underline */
  transform: translateY(-2px);  /* Slight lift effect on hover */
}

